function currencyToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ''));
}

function numberToCurrency(value) {
    const currency = 'GBP';
    const options = {
        maximumFractionDigits : 2,
        currency              : currency,
        style                 : 'currency',
        currencyDisplay       : 'symbol'
    };
    return (value || value === 0)
        ? currencyToNumber(value).toLocaleString(undefined, options)
        : '£0.00';
}

$(function() {
    Promise.all(
        $('[data-event-row-id]').map(function() {
            loadEventData($(this));
        })
    ).then(function() {
        $('.event-list-rows').removeClass('d-none');
        $('.event-list-spinner').addClass('d-none');
    });

    function loadEventData(row) {
        return new Promise(function(resolve) {
            const eventId = row.data('event-row-id');
            const stringAttributes = ['tickets_sold_status'];
            const currencyAttributes = [
                'total_gross',
                'total_discounts',
                'tfg_revenue',
                'promoter_revenue',
                'total_manual_payments',
                'total_balance'
            ];
            $.getJSON('/admin/events/' + eventId + '.json', function (data) {
                stringAttributes.forEach(function(attr) {
                    row.find('[data-event-attribute="' + attr + '"]').html(data[attr]);
                });
                currencyAttributes.forEach(function(attr) {
                    const value = numberToCurrency(data[attr] / 100);
                    row.find('[data-event-attribute="' + attr + '"]').html(value);
                });
            }).then(resolve);
        });
    }
});
