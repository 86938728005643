import { Controller } from 'stimulus'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import Rails from '@rails/ujs'
import resetBasketTimerEvent from '../events/reset_basket_timer'
import cartTotal from '../events/cart_total'

export default class extends Controller {
  static targets = [
    'modal',
    'modalSpinner',
    'modalConnectionError',
    'modalConnectionErrorMessage',
    'modalTransactionError',
    'modalTransactionErrorMessage',
    'modalTransactionEventTitle',
    'modalTransactionTicketTitle',
    'modalTransactionQuantityRequested',
    'modalTransactionQuantityFulfilled',
    'price',
    'quantitySelect',
    'rawPrice',
    'bookingFee',
    'ticketFeeValue',
    'ticketFeeTotal',
    'quantity',
    'rawFees',
    'subtotal',
    'donation',
    'donationSelect',
    'totalPrice',
    'vatAmount',
  ]

  #basketSummaryModal = new Modal(this.modalTarget, {
    backdrop: 'static',
    keyboard: false
  })

  onChangeCartItemQuantity(e) {
    this.#modalShow()

    const cartItemID = e.target.parentElement.parentElement.dataset.cartItemId
    const quantity = e.target.value
    const url = `/basket/cart_items/${cartItemID}.json?quantity=${quantity}`

    Rails.ajax({
      type: 'PATCH',
      url: url,
      success: (response) => {
        this.#applyTicketToCart(response.cart)
        this.#generateSelectTagForTicketProducts(response.transaction.ticket_id)
        if (response.valid && !response.altered) {
          this.#basketSummaryModal.hide()
        } else {
          this.modalTransactionErrorMessageTarget.innerHTML = response.transaction.message
          this.modalTransactionEventTitleTarget.innerHTML = response.transaction.event_title
          this.modalTransactionTicketTitleTarget.innerHTML = response.transaction.ticket_title
          this.modalTransactionQuantityRequestedTarget.innerHTML = response.transaction.quantity_requested_tickets
          this.modalTransactionQuantityFulfilledTarget.innerHTML = response.transaction.quantity_fulfilled_tickets
          this.#hideElements(this.modalSpinnerTargets)
          this.#hideElements(this.modalConnectionErrorTargets)
          this.#showElements(this.modalTransactionErrorTargets)
        }
      },
      error: () => this.#handleConnectionError()
    })
  }

  onChangeCartItemProductQuantity(e) {
    this.#modalShow()

    const productItemID = e.target.parentElement.parentElement.dataset.productId
    const quantity = e.target.value

    this.#updateCartItemProductRequest(productItemID, quantity)
  }

  #updateCartItemProductRequest(productItemID, quantity) {
    const url = `/basket/cart_item_products/${productItemID}.json?quantity=${quantity}`

    Rails.ajax({
      type: 'PATCH',
      url: url,
      success: (response) => {
        this.#applyProductToCart(response.cart)
        if (response.valid && !response.altered) {
          this.#basketSummaryModal.hide()
        } else {
          this.modalTransactionErrorMessageTarget.innerHTML = response.transaction.message
          this.modalTransactionEventTitleTarget.innerHTML = response.transaction.event_title
          this.modalTransactionTicketTitleTarget.innerHTML = response.transaction.ticket_title
          this.modalTransactionQuantityRequestedTarget.innerHTML = response.transaction.quantity_requested_products
          this.modalTransactionQuantityFulfilledTarget.innerHTML = response.transaction.quantity_fulfilled_products
          this.#hideElements(this.modalSpinnerTargets)
          this.#hideElements(this.modalConnectionErrorTargets)
          this.#showElements(this.modalTransactionErrorTargets)
        }
      },
      error: () => this.#handleConnectionError()
    })
  }

  onChangeDonation(e) {
    this.#modalShow()

    const donation = e.target.value
    const url = `/basket/donation.json?donation=${donation}`

    Rails.ajax({
      type: 'PATCH',
      url: url,
      success: (response) => {
        this.#applyTicketToCart(response.cart)
        if (response.valid) {
          this.#basketSummaryModal.hide()
        } else {
          this.#handleConnectionError()
        }
      },
      error: () => this.#handleConnectionError()
    })
  }

  #applyTicketToCart(cartData) {
    cartData['cart_items'].forEach(cartItem => {
      this.#updateCartItem(this.priceTargets, cartItem['id'], cartItem['formatted_price'])
      this.#updateCartItem(this.rawPriceTargets, cartItem['id'], cartItem['formatted_raw_price'])
      this.#updateCartItem(this.bookingFeeTargets, cartItem['id'], cartItem['formatted_booking_fee'])
      this.#updateCartItem(this.quantityTargets, cartItem['id'], cartItem['formatted_quantity'])
      this.#updateCartItem(this.rawFeesTargets, cartItem['id'], cartItem['formatted_raw_fees'])
      this.#updateCartItemFees(cartItem['cart_item_fees'])
      this.#generateSelectTag(cartItem)
    })

    cartData['subtotals'].forEach(subtotal => {
      this.#updateEvent(this.subtotalTargets, subtotal['event_id'], subtotal['formatted_subtotal'])
    })

    document.querySelector('#new_checkout_stripe_payment_proxy').dataset.amount = cartData['total_price']
    document.dispatchEvent(cartTotal)

    this.donationSelectTarget.value = cartData['donation']
    this.donationTarget.innerHTML = cartData['formatted_donation']
    this.totalPriceTarget.innerHTML = cartData['formatted_total_price']
    if (this.hasVatAmountTarget) {
      this.vatAmountTarget.innerHTML = cartData['formatted_vat_amount']
    }

    this.#toggleStripeCardDetails(cartData['total_price'])

    document.dispatchEvent(resetBasketTimerEvent)
  }

  #applyProductToCart(cartData) {
    cartData['cart_item_products'].forEach(cartItemProduct => {
      this.#updateCartItemProduct(this.priceTargets, cartItemProduct['product_id'], cartItemProduct['formatted_price'])
      this.#updateCartItemProduct(this.rawPriceTargets, cartItemProduct['product_id'], cartItemProduct['formatted_raw_price'])
      this.#updateCartItemProduct(this.bookingFeeTargets, cartItemProduct['product_id'], cartItemProduct['formatted_fee'])
      this.#updateCartItemProduct(this.quantityTargets, cartItemProduct['product_id'], cartItemProduct['formatted_quantity'])
      this.#updateCartItemProduct(this.rawFeesTargets, cartItemProduct['product_id'], cartItemProduct['formatted_raw_fees'])
      this.#generateSelectTagForProduct(cartItemProduct)
    })

    cartData['subtotals'].forEach(subtotal => {
      this.#updateEvent(this.subtotalTargets, subtotal['event_id'], subtotal['formatted_subtotal'])
    })

    document.querySelector('#new_checkout_stripe_payment_proxy').dataset.amount = cartData['total_price']
    document.dispatchEvent(cartTotal)

    this.totalPriceTarget.innerHTML = cartData['formatted_total_price']
    this.#toggleStripeCardDetails(cartData['total_price'])
  }

  #toggleStripeCardDetails(totalPrice) {
    const details = document.querySelector('#stripe-details-container')
    if (totalPrice > 0) {
      details.classList.remove('d-none')
    } else {
      details.classList.add('d-none')
    }
  }

  #generateSelectTag(cartItem) {
    const quantitySelect = this.quantitySelectTargets.find(e =>
      e.parentElement.parentElement.dataset.cartItemId == cartItem['id']
    )
    quantitySelect.innerHTML = ''

    const optionValues = [...Array(cartItem.quantity_options['quantity_max'] + 1).keys()]
    const optionLabels = optionValues.map((i) => `x ${i}`)

    for (let index = 0; index < optionLabels.length; index++) {
      const option = document.createElement('option')
      option.value = index
      option.text = optionLabels[index]
      if (index === cartItem.quantity_options['quantity_selected']) {
        option.selected = 'selected'
      }
      quantitySelect.appendChild(option)
    }
  }

  #generateSelectTagForProduct(cartItemProduct) {
    const quantitySelect = this.quantitySelectTargets.find(e =>
      e.parentElement.parentElement.dataset.productId == cartItemProduct['product_id']
    )
    quantitySelect.innerHTML = ''

    const optionValues = [...Array(cartItemProduct.quantity_options['quantity_max'] + 1).keys()]
    const optionLabels = optionValues.map((i) => `x ${i}`)

    for (let index = 0; index < optionLabels.length; index++) {
      const option = document.createElement('option')
      option.value = index
      option.text = optionLabels[index]
      if (index === cartItemProduct.quantity_options['quantity_selected']) {
        option.selected = 'selected'
      }
      quantitySelect.appendChild(option)
    }
  }

  #generateSelectTagForTicketProducts(ticketId) {
    const quantitySelects = this.quantitySelectTargets.filter(e =>
      e.parentElement.parentElement.dataset.productTicketId == ticketId
    )

    quantitySelects.forEach(quantitySelect => {
      const productItemID = quantitySelect.parentElement.parentElement.dataset.productId
      const url = `/basket/cart_item_products/${productItemID}/quantity_max.json?`
      const existingSelect = quantitySelect.value

      Rails.ajax({
        type: 'GET',
        url: url,
        success: (response) => {
          quantitySelect.innerHTML = ''

          const optionValues = [...Array(response.quantity_max + 1).keys()]
          const optionLabels = optionValues.map((i) => `x ${i}`)

          for (let index = 0; index < optionLabels.length; index++) {
            const option = document.createElement('option')
            option.value = index
            option.text = optionLabels[index]
            if (index == existingSelect) {
              option.selected = 'selected'
            } else if ((index == optionLabels.length - 1) && (existingSelect >= optionLabels.length)) {
              option.selected = 'selected'
              this.#updateCartItemProductRequest(parseInt(productItemID), index)
            }
            quantitySelect.appendChild(option)
          }

        },
        error: () => this.#handleConnectionError()
      })
    })

  }

  #updateCartItem(targets, cartItemId, value) {
    const elements = targets.filter(e => e.parentElement.dataset.cartItemId == cartItemId)
    elements.forEach(element => element.innerHTML = value)
  }

  #updateCartItemFees(cartItemFeesData) {
    cartItemFeesData.forEach(cartItemFee => {
      const ticketFeeId = cartItemFee['ticket_fee_id']
      const byTicketFee = (e) => e.parentElement.dataset.ticketFeeId == ticketFeeId
      this.ticketFeeValueTargets.find(byTicketFee).innerHTML = cartItemFee['formatted_value']
      this.ticketFeeTotalTargets.find(byTicketFee).innerHTML = cartItemFee['formatted_total_value']
    })
  }

  #updateCartItemProduct(targets, cartItemProductId, value) {
    const element = targets.find(e => e.parentElement.dataset.productId == cartItemProductId)
    element.innerHTML = value
  }

  #updateEvent(targets, eventId, value) {
    const elements = targets.filter(e => e.parentElement.dataset.eventId == eventId)
    elements.forEach(element => element.innerHTML = value)
  }

  #modalShow() {
    this.#basketSummaryModal.show()
    this.#showElements(this.modalSpinnerTargets)
    this.#hideElements(this.modalConnectionErrorTargets)
    this.#hideElements(this.modalTransactionErrorTargets)
  }

  #handleConnectionError() {
    const line1 = '<div>There was an error connecting to the server.</div>'
    const line2 = '<div>Please check your connection and try again.</div>'
    const line3 = '<div>It might help to <a href="">refresh your page</a>.</div>'
    this.modalConnectionErrorMessageTarget.innerHTML = `${line1} ${line2} ${line3}`
    this.#hideElements(this.modalSpinnerTargets)
    this.#hideElements(this.modalTransactionErrorTargets)
    this.#showElements(this.modalConnectionErrorTargets)
  }

  #hideElements(elements) {
    elements.forEach(element => {
      element.classList.add('d-none')
    })
  }

  #showElements(elements) {
    elements.forEach(element => {
      element.classList.remove('d-none')
    })
  }
}
